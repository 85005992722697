import { styled } from "styled-components";
import styledMUI from "@mui/material/styles/styled";
import AccountBalanceWalletSharpIcon from '@mui/icons-material/AccountBalanceWalletSharp';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PaidIcon from '@mui/icons-material/Paid';

export default function Info() {
    return (
        <Container>
            <SubTitle>Para desenvolvedores usuários da API</SubTitle>
            <Desc>
                A plataforma da Maritaca AI foi criada para gerenciar o uso e consumo de programadores ou empresas que utilizem os nossos modelos via <strong>API</strong>. Atualmente suportamos as seguintes funcionalidades:
            </Desc>
            <ItemContainer>
                <TitleContainer>
                    <StyledAccountBalanceIcon />
                    <ItemTitle style={{ color: "#38694e" }}>Controle de Gastos</ItemTitle>
                </TitleContainer>
                <ItemList>
                    <Item>
                        Gastos diários e mensais
                    </Item>
                    <Item>
                        Recibos de recargas
                    </Item>
                    <Item>
                        Número de requisições realizadas
                    </Item>
                </ItemList>
            </ItemContainer>
            <ItemContainer>
                <TitleContainer>
                    <StyledKeyOutlinedIcon />
                    <ItemTitle style={{ color: "#753916" }}>Chaves de API</ItemTitle>
                </TitleContainer>
                <ItemList>
                    <Item>
                        Crie novas chaves
                    </Item>
                    <Item>
                        Visualize chaves ativas
                    </Item>
                    <Item>
                        Revogue chaves antigas
                    </Item>
                </ItemList>
            </ItemContainer>
            <ItemContainer>
                <TitleContainer>
                    <StyledSmartToyIcon />
                    <ItemTitle style={{ color: "#386e9b" }}>Modelos</ItemTitle>
                </TitleContainer>
                <ItemList>
                    <Item>
                        Liste os modelos disponíveis
                    </Item>
                    <Item>
                        Confira os limites de uso
                    </Item>
                    <Item>
                        Confira o preço de cada modelo
                    </Item>
                </ItemList>
            </ItemContainer>
            <ItemContainer>
                <TitleContainer>
                    <StyledPaidIcon />
                    <ItemTitle style={{ color: "#992e49" }}>Recarga</ItemTitle>
                </TitleContainer>
                <ItemList>
                    <Item>
                        Recarregue seus créditos via cartão de crédito ou boleto
                    </Item>
                    <Item>
                        Configure a recarga automática
                    </Item>
                    <Item>
                        Use códigos promocionais
                    </Item>
                </ItemList>
            </ItemContainer>
        </Container>
    );
}

const SubTitle = styled.h1`
    font-size: 2rem;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.dark_blue};
    font-family: 'Plus Jakarta Sans', sans-serif;
`;

const StyledPaidIcon = styledMUI(PaidIcon)`
    font-size: 21px;
    margin-right: 10px;
    margin-bottom: 5px;
    color: #992e49;
`;

const StyledKeyOutlinedIcon = styledMUI(KeyOutlinedIcon)`
    font-size: 21px;
    margin-right: 10px;
    margin-bottom: 5px;
    color: #753916;
`;
const StyledSmartToyIcon = styledMUI(SmartToyIcon)`
    font-size: 21px;
    margin-right: 10px;
    margin-bottom: 5px;
    color: #386e9b;
`;

const StyledAccountBalanceIcon = styledMUI(AccountBalanceWalletSharpIcon)`
    font-size: 26px;
    margin-right: 10px;
    margin-bottom: 5px;
    color: #38694e;
`;

const ItemList = styled.ul`
    margin: 0;
    padding: 0;
`;

const Item = styled.li`
    margin-bottom: 8px;
    padding-left: 20px;
    font-size: 1.1rem;
    color: ${(props) => props.theme.dark_blue};
    font-family: 'Plus Jakarta Sans', sans-serif;
    position: relative;
    list-style: none;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 6px;
        height: 6px;
        background-color: #919291;
        border-radius: 50%;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 15px;
    border: 1px solid ${(props) => props.theme.input_border};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    padding: 15px;
`;

const ItemTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.dark_blue};
    font-family: 'Plus Jakarta Sans', sans-serif;
`;

const ItemDesc = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.dark_blue};
    font-family: 'Plus Jakarta Sans', sans-serif;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.white_background};
    max-width: 1000px;
    width: 100%;
`;

const Desc = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.dark_blue};
    font-family: 'Plus Jakarta Sans', sans-serif;
`;
