import { styled } from "styled-components";
import SideMenu from "../components/Menus/SideMenu";
import Models from "../components/Models";
import UpperMenu from "../components/Menus/UpperMenu";
import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from "react";
import { TitleContext } from "../contexts/TitleContext";
import BillingDashboard from "../components/Dashboard";
import APIKeys from "../components/APIKeys";
import Recharge from "../components/Recharge";
import Info from "../components/Info";
// import PlaygroundComponent from "../components/Playground/PlaygroundComponent";

function Home() {
    const { isAuthenticated, isLoading } = useAuth0();
    const { title } = useContext(TitleContext);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            window.location.href = "/auth";
        }
    }, [isLoading, isAuthenticated]);

    return (
        <PageContainer>
            {!isLoading && isAuthenticated &&
                <>
                    <SideMenu/>
                    <InnerContainer>
                        <UpperMenu title={title} />
                        {title === "Plataforma" && <Info />} {/*Home page*/}
                        {title === "Modelos" && <Models />}
                        {title === "Meus Gastos" && <BillingDashboard />}
                        {title === "Chaves de API" && <APIKeys />}
                        {title === "Recarga" && <Recharge />}
                        {/* {title === "Playground" && <PlaygroundComponent />} */}
                    </InnerContainer>
                </>
            }
        </PageContainer>
    );
}

export default Home;


const PageContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.theme.white_background};

    @media (max-width: 768px) {
        overflow-x: hidden;
        max-width: 100vw;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 40px;
    padding-right: 70px;
    padding-top: 11px;
    overflow-y: auto;

    @media (max-width: 768px) {
        padding-left: 10px;
        padding-right: 10px;
    }

`;
