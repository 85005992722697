import React, { createContext, useState } from 'react';


interface TitleContextProps {
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const TitleContext = createContext<TitleContextProps>({
    title: 'Plataforma',
    setTitle: () => {},
});


export const TitleProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [title, setTitle] = useState<string>('Plataforma');

    return (
        <TitleContext.Provider value={{ title, setTitle }}>
            {children}
        </TitleContext.Provider>
    );
};
