import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, createTheme} from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TitleProvider } from './contexts/TitleContext';
import { SideMenuProvider } from './contexts/SideMenuContext';
import { PaymentProvider } from './contexts/PaymentContext';
import Theme from './shared/Theme';
import Models from './components/Models';
import BillingDashboard from './components/Dashboard';
import APIKeys from './components/APIKeys';
import PlaygroundComponent from './components/Playground/PlaygroundComponent';
import Recharge from './components/Recharge';
import NotFound from './pages/NotFound';
import Info from './components/Info';

declare module '@mui/material/styles' {
    interface Theme {
        variables: typeof Theme;
    }
    interface ThemeOptions {
        variables?: typeof Theme;
    }
}

function App() {
    const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

    const muiTheme = createTheme({
        variables: {
          ...Theme
        }
    });

    return (
        <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={Theme}>
                <TitleProvider>
                    <SideMenuProvider>
                        <PaymentProvider>
                            <Auth0Provider
                                domain={auth0Domain as string}
                                clientId={auth0ClientId as string}
                                cacheLocation='localstorage'
                                authorizationParams={{
                                    redirect_uri: window.location.origin,
                                    audience: `https://chat.maritaca.ai/api`,
                                    scope: "chat:messages profile email"
                                }}
                            >
                                <Router>
                                    <div className="App">
                                        <Routes>
                                            <Route path="/" element={<Home />}>
                                                <Route path="home" element={<Info />} />
                                                <Route path="modelos" element={<Models />} />
                                                <Route path="meus-gastos" element={<BillingDashboard />} />
                                                <Route path="chaves-de-api" element={<APIKeys />} />
                                                <Route path="recarga" element={<Recharge />} />
                                                {/* <Route path="playground" element={<PlaygroundComponent />} /> */}
                                            </Route>
                                            <Route path="/auth" element={<Login />} />
                                            <Route path="*" element={<NotFound />} />
                                        </Routes>
                                    </div>
                                </Router>
                            </Auth0Provider>
                        </PaymentProvider>
                    </SideMenuProvider>
                </TitleProvider>
            </ThemeProvider>
        </MuiThemeProvider>
    );
}

export default App;
